<template>
	<div>
		<cover></cover>

		<!-- <event-express></event-express> -->
		<sfc></sfc>

		<v-img
			:src="require('@/assets/images/features/sdc2022-team3.png')"
			height="calc(100vh - 64px)"
			class="img_fixed"
		></v-img>

		<highlights></highlights>

		<v-img
			:src="require('@/assets/images/features/sdc2022-site1.png')"
			height="calc(100vh - 64px)"
			class="img_fixed"
		></v-img>

		<affiliates></affiliates>

		<v-img
			:src="require('@/assets/images/features/sdc2018-oc.png')"
			height="calc(100vh - 64px)"
			class="img_fixed"
		></v-img>

		<follow-us></follow-us>
	</div>
</template>



<script>
import Cover from "@/views/home/sections/Cover.vue";
import EventExpress from "@/views/home/sections/EventExpress.vue";
import Sfc from "@/views/home/sections/Sfc.vue";
import Highlights from "@/views/home/sections/Highlights.vue";
import Affiliates from "@/views/home/sections/Affiliates.vue";
import FollowUs from "@/views/home/sections/FollowUs.vue";

export default {
	name: "Home",

	components: {
		Cover,
		EventExpress,
		Sfc,
		Highlights,
		Affiliates,
		FollowUs,
	},
};
</script>

<style scope>
.img_fixed .v-image__image {
	background-attachment: fixed !important;
}
</style>
